import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userDetails: null,
};

export const userDetailSlice = createSlice({
    name: "userDetailMarkUp",
    initialState,
    reducers: {
        setUserDetails(state, action) {
            state.userDetails = action.payload;
        },
    },
});

export const { setUserDetails } = userDetailSlice.actions;
export default userDetailSlice.reducer;
