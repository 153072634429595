// Create a slice for the pop-up state
import { createSlice } from '@reduxjs/toolkit';

const popUpSlice = createSlice({
    name: 'popUp',
    initialState: {
        isOpen: false,
    },
    reducers: {
        openPopUp: (state) => {
            state.isOpen = true;
        },
        closePopUp: (state) => {
            state.isOpen = false;
        },
    },
});

export const { openPopUp, closePopUp } = popUpSlice.actions;
export default popUpSlice.reducer;
