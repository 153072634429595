import { configureStore } from '@reduxjs/toolkit'
import headerSlice from '../features/common/headerSlice'
import modalSlice from '../features/common/modalSlice'
import rightDrawerSlice from '../features/common/rightDrawerSlice'
import leadsSlice from '../features/leads/leadSlice'
import { authApi } from './api/authApi'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import { api } from './api/api'
import userReducer from './api/userDetails/userDetail-slice'
import popUpReducer from './api/popUp/PopUpSlice'
import topUpPopUpReducer from './api/topUpPopUp/top-up-pop-up-slice'


export const store = configureStore({
  reducer: {
    header: headerSlice,
    rightDrawer: rightDrawerSlice,
    modal: modalSlice,
    lead: leadsSlice,
    user: userReducer,
    popUp: popUpReducer,
    topUpPopUp: topUpPopUpReducer,
    [authApi.reducerPath]: authApi.reducer,
    [api.reducerPath]: api.reducer
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ serializableCheck: false }).concat(authApi.middleware).concat(api.middleware)
  }
})

setupListeners(store.dispatch);