// Create a slice for the pop-up state
import { createSlice } from '@reduxjs/toolkit';

const topUpPopUp = createSlice({
    name: 'topUpPopUp',
    initialState: {
        isOpen: false,
    },
    reducers: {
        openTopUpPopUp: (state) => {
            state.isOpen = true;
        },
        closeTopUpPopUp: (state) => {
            state.isOpen = false;
        },
    },
});

export const { openTopUpPopUp, closeTopUpPopUp } = topUpPopUp.actions;
export default topUpPopUp.reducer;