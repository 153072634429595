// export const app_url = 'http://localhost:3001';
// export const app_url = 'https://usdt.goldenlink.us:3001';
export const app_url = 'https://erbil.goldenlink.us:3002';

export const checkStatusCode = (status, message) => {
    if (status === 0) {
        throw new Error(message);
    }
}

export const formatAmount = (amount) => {
    const formattedAmount = parseFloat(amount)?.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    return formattedAmount;
}


export const itemsPerPage = 30;