import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { app_url } from "../config/helper";


let baseUrl = app_url;
export const api = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({
        baseUrl: `${baseUrl}`,
        prepareHeaders: (headers, { getState }) => {
            let token = localStorage.getItem("token");
            headers.set("authorization", `Bearer ${token}`);
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getCustomers: builder.mutation({
            query: (body) => ({
                url: `/v1/customer/list`,
                method: "POST",
                body: body
            }),
        }),
        createCustomers: builder.mutation({
            query: (body) => ({
                url: `/v1/customer/add`,
                method: "POST",
                body: body
            }),
        }),
        deleteCustomers: builder.mutation({
            query: (body) => ({
                url: `/v1/customer/remove`,
                method: "DELETE",
                body: body
            }),
        }),
        updateCustomer: builder.mutation({
            query: (body) => ({
                url: `/v1/customer/update`,
                method: "PUT",
                body: body
            }),
        }),
        getUsers: builder.mutation({
            query: (body) => ({
                url: `/v1/user/list`,
                method: "POST",
                body: body
            }),
        }),
        createUsers: builder.mutation({
            query: (body) => ({
                url: `/v1/user/add`,
                method: "POST",
                body: body
            }),
        }),
        deleteStoreUsers: builder.mutation({
            query: (body) => ({
                url: `/v1/user/remove`,
                method: "DELETE",
                body: body
            }),
        }),
        updateStoreUsers: builder.mutation({
            query: (body) => ({
                url: `/v1/user/update`,
                method: "PUT",
                body: body
            }),
        }),
        createTransaction: builder.mutation({
            query: (body) => ({
                url: `/v1/buyer/transaction/add`,
                method: "POST",
                body: body
            }),
        }),
        getTransactions: builder.mutation({
            query: (body) => ({
                url: `/v1/buyer/transaction/list`,
                method: "POST",
                body: body
            }),
        }),
        removeTransaction: builder.mutation({
            query: (body) => ({
                url: `/v1/buyer/transaction/remove`,
                method: "DELETE",
                body: body
            }),
        }),
        getSettings: builder.mutation({
            query: () => ({
                url: `/v1/setting/get`,
                method: "GET",
            }),
        }),
        updateSettings: builder.mutation({
            query: (body) => ({
                url: `/v1/setting/add`,
                method: "POST",
                body: body
            }),
        }),
        getTotalTransactions: builder.mutation({
            query: (dateRange) => ({
                url: `/v1/buyer/transaction/total`,
                method: "GET",
                params: {
                    startDate: dateRange.startDate,
                    endDate: dateRange.endDate,
                    userRole: dateRange.userRole,
                    employeeId: dateRange.employeeId
                },
            }),
        }),
        getTransactionsCount: builder.mutation({
            query: (dateRange) => ({
                url: `/v1/buyer/transaction/count`,
                method: "GET",
                params: {
                    userRole: dateRange.userRole,
                    employeeId: dateRange.employeeId
                },
            }),
        }),
        getIqdAmount: builder.mutation({
            query: () => ({
                url: `/v1/iqdAmt/get`,
                method: "GET",
            }),
        }),
        updateIqdAmount: builder.mutation({
            query: (body) => ({
                url: `/v1/iqdAmt/update`,
                method: "PUT",
                body: body
            }),
        }),
        createTopUp: builder.mutation({
            query: (body) => ({
                url: `/v1/top-up/add`,
                method: "POST",
                body: body
            }),
        }),
        getTopUp: builder.mutation({
            query: (body) => ({
                url: `/v1/top-up/list`,
                method: "POST",
                body: body
            }),
        }),
        updateTopUp: builder.mutation({
            query: (body) => ({
                url: `/v1/top-up/update`,
                method: "PUT",
                body: body
            }),
        }),
        deleteTopUp: builder.mutation({
            query: (body) => ({
                url: `/v1/top-up/remove`,
                method: "DELETE",
                body: body
            }),
        }),
        getEmployeeBasedOnRoles: builder.mutation({
            query: (body) => ({
                url: `/v1/user/get-employee`,
                method: "POST",
                body: body
            }),
        }),
        getTopUpCount: builder.mutation({
            query: (dateRange) => ({
                url: `/v1/top-up/total`,
                method: "GET",
                params: {
                    startDate: dateRange.startDate,
                    endDate: dateRange.endDate,
                    userRole: dateRange.userRole,
                    employeeId: dateRange.employeeId
                },
            }),
        }),
        createECommerce: builder.mutation({
            query: (body) => ({
                url: `/v1/ecommerce/add`,
                method: "POST",
                body: body
            }),
        }),
        getECommerce: builder.mutation({
            query: (body) => ({
                url: `/v1/ecommerce/list`,
                method: "POST",
                body: body
            }),
        }),
        updateECommerce: builder.mutation({
            query: (body) => ({
                url: `/v1/ecommerce/update`,
                method: "PUT",
                body: body
            }),
        }),
        deleteECommerce: builder.mutation({
            query: (body) => ({
                url: `/v1/ecommerce/remove`,
                method: "DELETE",
                body: body
            }),
        }),
        getECommerceCount: builder.mutation({
            query: (dateRange) => ({
                url: `/v1/ecommerce/total`,
                method: "GET",
                params: {
                    startDate: dateRange.startDate,
                    endDate: dateRange.endDate,
                    userRole: dateRange.userRole,
                    employeeId: dateRange.employeeId
                },
            }),
        }),
        getTransactionsByCustomer: builder.mutation({
            query: (body) => ({
                url: `/v1/buyer/transaction/customer`,
                method: "POST",
                body: body
            }),
        }),
    }),
});

// Export hooks for usage in functional components
export const {
    useGetCustomersMutation,
    useCreateCustomersMutation,
    useDeleteCustomersMutation,
    useUpdateCustomerMutation,
    useGetUsersMutation,
    useCreateUsersMutation,
    useDeleteStoreUsersMutation,
    useUpdateStoreUsersMutation,
    useCreateTransactionMutation,
    useGetSettingsMutation,
    useUpdateSettingsMutation,
    useGetTransactionsMutation,
    useRemoveTransactionMutation,
    useGetTotalTransactionsMutation,
    useGetTransactionsCountMutation,
    useUpdateIqdAmountMutation,
    useGetIqdAmountMutation,
    useCreateTopUpMutation,
    useGetTopUpMutation,
    useUpdateTopUpMutation,
    useDeleteTopUpMutation,
    useGetEmployeeBasedOnRolesMutation,
    useGetTopUpCountMutation,
    useCreateECommerceMutation,
    useGetECommerceMutation,
    useUpdateECommerceMutation,
    useDeleteECommerceMutation,
    useGetECommerceCountMutation,
    useGetTransactionsByCustomerMutation
} = api;
